import "./styles.scss";
import React from "react";

import { useLocale } from "context/locale";

import OfferTiles from "components/OfferTiles";

const OurOffices = ({ data }) => {
  const { t } = useLocale();
  return (
    <div className="offer-our-offices">
      <div className="container">
        <h2>{t("Our offices")}</h2>
        <OfferTiles data={data} />
      </div>
    </div>
  );
};

export default OurOffices;
