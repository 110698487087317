import "./styles.scss";

import React from "react";

import { useLocale } from "context/locale";

import FacilitiesTile from "components/FacilitiesTiles/components/FacilitiesTile";

const Facilities = ({ data }) => {
  const { t } = useLocale();
  return (
    <div className="offer-facilities-tiles">
      <div className="container">
        <h2>{t("Amenities")}</h2>
        <div className="offer-facilities-tiles__items">
          {data.map((item, index) => (
            <FacilitiesTile key={index} {...item} title_variant="small" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Facilities;
