import "./styles.scss";
import React from "react";

const PageHeader = ({ label }) => {
  return (
    <section className="page-header">
      <div className="container">
        <h1>{label}</h1>
      </div>
    </section>
  );
};

export default PageHeader;
