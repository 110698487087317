import "./styles.scss";

import React from "react";
import Button from "components/Button";

const BottomMessage = ({ text, button }) => {
  return (
    <div className="bottom-message">
      <div className="container">
        <p>{text}</p>
        {!!button && <Button href={button?.href}>{button?.label}</Button>}
      </div>
    </div>
  );
};

export default BottomMessage;
