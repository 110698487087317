import React from "react";
import { graphql } from "gatsby";

import { useLocale } from "context/locale";

import Layout from "components/Layout";
import PageHeader from "components/PageHeader";
import HubInfo from "components/HubInfo";

import Facilities from "../page_components/Offer/Facilities";
import OurOffices from "../page_components/Offer/OurOffices";
import BottomMessage from "../components/BottomMessage";

const BottomInfo = () => {
  const { t } = useLocale();
  return (
    <BottomMessage
      text={t(
        "Do you have a question, Are you interested in our offer? Contact us"
      )}
      button={{ label: t("Send an inquiry"), href: t("contact_link") }}
    />
  );
};

const Offer = ({
  location,
  pageContext: { translations },
  data: { offer, offers, facilities, contact },
}) => {
  const facilities_data = facilities.nodes
    ?.map(({ acfFacilities: { name, image, position } }) => ({
      text: name,
      icon: image,
      position,
    }))
    .sort((a, b) => a.position - b.position);
  const hub_info = offer?.acfHubInfo?.hubinfoContent?.[0];

  return (
    <Layout
      seo={{
        title: offer.seo.title || offer.title,
        description: offer.seo.metaDesc || "",
      }}
      translations={translations}
      location={location}
      contact_data={contact?.acfContact}
    >
      <PageHeader label={offer?.title || ""} />
      {hub_info?.title && (
        <HubInfo
          title={hub_info.title}
          text={hub_info?.text}
          image={hub_info?.image?.sourceUrl}
        />
      )}
      <Facilities data={facilities_data} />
      <OurOffices data={offers?.nodes} />
      <BottomInfo />
    </Layout>
  );
};

export default Offer;

export const query = graphql`
  query ($id: Int!, $code: WpLanguageCodeEnum!) {
    offer: wpPage(databaseId: { eq: $id }) {
      title
      seo {
        title
        metaDesc
      }
      acfHubInfo {
        hubinfoContent {
          title
          text
          image {
            sourceUrl
          }
        }
      }
    }
    offers: allWpOffer(filter: { language: { code: { eq: $code } } }) {
      nodes {
        acfOffer {
          price
          title
          count
          image {
            sourceUrl
          }
        }
      }
    }
    facilities: allWpFacilitie(filter: { language: { code: { eq: $code } } }) {
      nodes {
        acfFacilities {
          name
          image
          position
        }
      }
    }
    contact: wpPage(id: { eq: "cG9zdDoxOTE=" }) {
      acfContact {
        email
        phone
        address
      }
    }
  }
`;
